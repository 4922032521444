;(function() {
"use strict";

const newUserInviteComponent = {
  templateUrl: 'components/manager/manage-users/invite/add-user.html',
  controller: 'newUserInviteController',
  controllerAs: 'vm',
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  },
};

function newUserInviteController($filter, $log, $sessionStorage, $state, Alerts, Auth, MetaModel, Project, StreuthApi, FieldworkerModel, $scope) {
  let vm = this;
  const invites = StreuthApi.Invites;
  vm.project_id = Project.getProjectID();
  vm.project_code = Project.getProjectCode();

  vm.emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  vm.mobilePattern = /^(\+[0-9]{2}|0)[0-9]{6,10}$/;
  vm.newUserInviteForm = {};
  vm.invitePending = false;
  // selection of role for the new user:
  vm.roleOpts = [
      { id: '', title: 'Select Role', isDefault: true }, // default selection
      { id: 'manager',     title: 'Manager' },
      { id: 'fieldworker', title: 'Fieldworker' },
      { id: 'telephone',   title: 'Telephone' }
    ];

  function makeSelect(project) {
    let is_checked = project.code === vm.project_code;
    let project_ref = {
       id:    project.project_id,
       code:  project.code,
       label: project.name,
       isChecked: is_checked,
    };

    return project_ref;
  }

  vm.projects = _(Auth.current.user.projects)
                  .filter({ status: 'active' })
                  .map(makeSelect)
                  .sortBy('label')
                  .value();

  $scope.$watch(() => vm.invite, () => {
    vm.formError = null;
  }, true);

  vm.sendInvitation = function(invite) {
    // tidy up mobile number because api says
    // "The caller should strip out any spaces, dashes, parens or other punctuation."
    // ... don't use \W in the character class - that would remove any leading '+'
    //     (which is valid for a mobile number)
    invite.mobile = invite.mobile.replace(/[-_\s'";.,(){}\[\]\/\|]/g, '');

    // selected role must be passed in an array of attributes:
    let projectAttrs = [];
    let projectIds = [];
    let projects = [];

    vm.projects.forEach((item) => {
      if (item.isChecked) {
        projectAttrs.push('project.' + item.code);
        projectIds.push(item.id);
        projects.push({
            name: item.label,
            code: item.code,
            id:   item.id,
        });
      }
    });

    invite.attributes = [ ...projectAttrs, invite.role_id ];
    if ( invite.role_id === 'manager' ) {
        // additional manager-specific attributes:
        invite.attributes.push('invite');
        invite.managed_attributes = [ 'fieldworker', 'telephone', 'manager', 'invite', ...projectAttrs ];
    }

    // handy debugging - see what we're posting to streuth:
    // console.log(invite);

    const inviter = Auth.current.user;
    invite.template_vars = {
        version: 1,
        inviter: {
            email:     inviter.email,
            full_name: inviter.full_name,
        },
        projects: projects, // { name, code, id }
        role: _.upperFirst(invite.role_id),
    };

    // Set the pingback data to match the template vars, so that we can use
    // those in any emails we send from the pingback handler.
    invite.pingback_data = invite.template_vars;

    vm.invitePending = true;
    invites.create(invite).then(
      response => {
        Alerts.add('success', response.message);
        vm.invitePending = false;
        vm.close({ $value: response.invite});
      },
      error => {
        vm.invitePending = false;
        if (error.status == 409 && error.data.conflict_type === 'user') {
          // conflict_with_id is user's uuid

          FieldworkerModel.activateProjects(error.data.conflict_with_id, invite.role_id, projectIds).$promise.then(
            rsp => {
              Alerts.removeAll();
              Alerts.add('success', rsp.message);
              vm.cancelInvitation();
            },
            err => {
              form_error(err.errorMessage);
            },
          );
        }
        else { // conflict_type = invite
          form_error(error.errorMessage);
        }
      }
    )
  };

  // in-form message display
  function form_error(msg) {
    Alerts.removeAll();
    vm.formError = msg;
  }

  vm.cancelInvitation = function() {
    vm.invite = {role_id: ''};
    vm.dismiss();
  };
}

angular
    .module('tmr-admin')
    .component('newUserInvite', newUserInviteComponent)
    .controller('newUserInviteController', newUserInviteController);
}());
